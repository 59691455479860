<template>
  <v-card class="mt-5">
    <v-toolbar
      dense
      flat
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="projectStartTitle" />
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-alert
        text
        prominent
        :color="userMessage.color"
        :icon="userMessage.icon"
      >
        <v-row no-gutters align="center" class="pl-5">
          <v-col cols="grow">
            <h3 class="headline" v-text="userMessage.title" />
            <span v-text="userMessage.subtitle" />
          </v-col>

          <v-col cols="auto" class="mx-1">
            <v-btn
              :loading="loadingDeclineBlueOverCapacity"
              :disabled="isLoading"
              color="secondary"
              outlined
              @click="declineBlueOverCapacity"
              v-text="$t('multiproject.blueOverCapacity.declineBlueOverCapacity')"
            />
          </v-col>

          <v-col cols="auto" class="mx-1">
            <v-btn
              :loading="loadingAcceptBlueOverCapacity"
              :disabled="isLoading"
              color="accent"
              @click="acceptBlueOverCapacity"
              v-text="$t('multiproject.blueOverCapacity.acceptBlueOverCapacity')"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'BlueOverCapacityContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingAcceptBlueOverCapacity: false,
    loadingDeclineBlueOverCapacity: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentStatus () {
      return this.currentPlayer.status
    },
    projectStartTitle () {
      return this.$t('multiproject.start.title.startPlanned', {
        week: this.currentPlayer.weekStartPlanned,
      })
    },
    blueResource () {
      return this.game.resourceList.find(item => item.color === 'blue')
    },
    userMessage () {
      return {
        title: this.$t('multiproject.blueOverCapacity.title', {
          resource: this.blueResource.name,
          capacity: this.game.blueOverCapacity,
        }),
        subtitle: this.$t('multiproject.blueOverCapacity.subtitle'),
        icon: this.$t('multiproject.blueOverCapacity.icon'),
        color: this.$t('multiproject.blueOverCapacity.color'),
      }
    },
    isLoading () {
      return this.loadingAcceptBlueOverCapacity || this.loadingDeclineBlueOverCapacity
    },
  },
  methods: {
    async acceptBlueOverCapacity () {
      this.loadingAcceptBlueOverCapacity = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.acceptBlueOverCapacity)
      this.loadingAcceptBlueOverCapacity = false
    },
    async declineBlueOverCapacity () {
      this.loadingDeclineBlueOverCapacity = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.declineBlueOverCapacity)
      this.loadingDeclineBlueOverCapacity = false
    },
  },
}
</script>
